@import "./colors.scss";

.header {
  display: flex;
  flex-direction: column;
  background-color: #ecf1f9;

  .language-button {
    margin-right: auto;
    padding: 15px 25px;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 35px;
    font-size: 22px;
    color: $primary-purple;
  }

  .header-img {
    margin: auto;
    width: 80%;
  }

  .title {
    color: $primary-purple;
    font-weight: bold;
    max-width: 75%;
    font-size: 45px;
    margin: auto;
    margin-bottom: 70px;
    text-align: center;
  }

  .donateToCampaign {
    background-color: $primary-purple;
    color: white;
    border: 1px solid $primary-purple;
    width: 75%;
    padding: 8px 20px;
    text-align: center;
    font-size: 25px;
    margin: auto;
    margin-bottom: 30px;
    border-radius: 30px;
  }

  .joinCampaign {
    background-color: white;
    color: $primary-purple;
    border: 1px solid $primary-purple;
    width: 75%;
    padding: 8px 20px;
    text-align: center;
    font-size: 25px;
    margin: auto;
    margin-bottom: 70px;
    border-radius: 30px;
  }

  .goal-container {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: auto;
    margin-bottom: 40px;

    .goal-numbers-container {
      display: flex;
      justify-content: space-between;

      .goal {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #4d4c4c;

        .goal-title {
          text-align: center;
          font-size: 20px;
          margin-bottom: 5px;
        }

        .goal-amount {
          text-align: center;
          font-weight: bold;
          font-size: 25px;
        }

        .currency {
          text-align: center;
        }
      }

      .white-separator {
        width: 4px;
        height: 100px;
        background-color: white;
      }
    }
  }
  .progress-bar-goal {
    display: flex;
    width: 70%;
    justify-content: flex-end;
    align-items: center;
    margin: auto;
    margin-top: 10px;

    .amount {
      color: #4d4c4c;
      font-weight: bold;
      font-size: 15px;
      margin: 0 4px;
    }
    .currency {
      font-size: 10px;
      color: gray;
    }
  }

  .header-last-title {
    margin: auto;
    margin-bottom: 30px;
    text-align: center;
    margin-top: 40px;
    font-size: 22px;
    font-weight: normal;
  }

  .hor-separate {
    background-color: $primary-purple;
    width: 15%;
    height: 1px;
    margin: auto;
    margin-bottom: 40px;
  }
}

.description {
  display: flex;
  flex-direction: column;
  align-items: center;

  .desc-slash {
    width: 30px;
    height: 5px;
    background-color: $primary-purple;
    transform: rotate(-45deg);
    margin: 40px 0px;
  }

  .descriptionTextMain {
    color: $primary-purple;
    margin: 0px 12px;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: justify;
    width: 90%;
  }

  .descriptionTextSub {
    color: $primary-purple;
    margin: 10px 12px;
    font-size: 18px;
    text-align: justify;
    width: 90%;
  }
}

.campaign-goals {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  background-color: #dfdddd;
  position: relative;
  padding-bottom: 50px;

  .campaignGoalsTitle {
    font-size: 32px;
    color: black;
    margin-left: auto;
    margin: 0px 100px;
    text-align: center;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 50px;
    z-index: 2;
  }

  .campaignGoalsText1 {
    color: $primary-purple;
    margin: 0px 12px;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
    z-index: 2;
  }

  .campaignGoalsText2 {
    color: $primary-purple;
    margin: 0px 12px;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    z-index: 2;
  }

  .absolute-percent {
    position: absolute;
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    top: 35%;

    .num {
      font-size: 90px;
      color: #ebe8e8;
      font-weight: bold;
      height: 80px;

      &:nth-child(3) {
        margin-top: -30px;
      }
    }

    .slash {
      width: 30px;
      height: 5px;
      background-color: white;
      transform: rotate(-45deg);
      margin: 40px 0px;
    }
  }
}

.aboutus-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #cdecff;
  position: relative;
  padding-bottom: 50px;

  .aboutusTitle {
    font-size: 32px;
    color: black;
    margin-left: auto;
    margin: 0px 150px;
    text-align: center;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 50px;
    z-index: 2;
  
  }

  .question-mark {
    position: absolute;
    transform: scale(-1, 1) translateX(50%);
    left: 50%;
    top: 15%;
    color: white;
    font-size: 80px;
    font-weight: bold;
    z-index: 1;
  }

  .aboutusText1 {
    color: $primary-purple;
    margin: 0px 15px;
    font-size: 18px;
    margin-bottom: 20px;
    text-align: justify;
    width: 90%;
  }

  .aboutusText2 {
    color: $primary-purple;
    margin: 0px 15px;
    font-size: 18px;
    text-align: justify;
    width: 90%;
  }
}

.approach {
  display: flex;
  flex-direction: column;
  margin-top: 40px;


  .approach-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0px 40px 30px 40px;
    .line {
      height: 8px;
      width: 70px;
      background-color: lightblue;
      margin: 15px 0px;
    }

    .approachTitle {
      font-size: 45px;
      font-weight: bold;
      width: 80%;
      color: $primary-purple;
    }
  }

  .text {
    margin: 0px 15px;
    font-size: 18px;
    line-height: 1.75;
    align-self: center;
    width: 90%;
    text-align: justify;
  }

  .subtitle {
    font-size: 28px;
    font-weight: bold;
    color: $primary-purple;
    margin: 20px 40px 10px 40px;
  }
}

.donateToCampaign {
  background-color: $primary-purple;
  color: white;
  border: 1px solid $primary-purple;
  width: 75%;
  padding: 8px 20px;
  text-align: center;
  font-size: 25px;
  margin: auto;
  margin-bottom: 30px;
  border-radius: 30px;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: $primary-purple;
  padding-bottom: 20px;

  .footerTitle {
    font-size: 32px;
    color: black;
    text-align: center;
    font-weight: bold;
    margin-top: 40px;
    margin-bottom: 30px;
  }

  .footerMessageSend {
    color: white;
    border: 1px solid white;
    border-radius: 30px;
    padding: 5px 10px;
    width: 55%;
    margin: auto;
    font-size: 32px;
  }

  .social-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    .social-container-title {
      width: 50%;
      text-align: center;
      color: white;
    }

    .media-icons {
      display: flex;
      justify-content: space-between;
      width: 45%;
      margin-top: 20px;

      .icon {
        width: 20px;
      }
    }
  }

  .bottom-footer {
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    padding: 0px 5px;
    margin-top: 50px;

    .img {
      width: 180px;
      height: 80px;
    }
  }
}
